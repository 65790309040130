<template>
  <a-spin :style="backCss" :spinning="loading">
    <div class="taskBoard">
      <div class="stageTitles" :style="{ minWidth: stages.length * 180 + 'px' }">
        <div
          class="stageTitle"
          :style="{ opacity: stage.status == '1' ? '0.4' : '' }"
          v-for="stage in stagesCopy"
          :key="stage.stageId"
        >
          {{ stage.stageName }}
        </div>
      </div>
      <div class="boardContent" :style="{ minWidth: stages.length * 180 + 'px' }">
        <div class="virtual-top" :style="'height:' + topLength + 'px'"></div>
        <div v-for="chapter in copyChapters" :key="chapter.chapterId" class="boardChapter chapter-li">
          <div v-for="stage in stagesCopy" :key="stage.stageId" class="boardStage">
            <div
              class="board-shadow"
              :style="
                chapter.stageIdTask && chapter.stageIdTask[stage.stageId] && chapter.stageIdTask[stage.stageId].isSelect
                  ? 'box-shadow: 5px 5px 10px rgb(0 0 0 / 10%), -1px 0 1px rgb(0 0 0 / 6%);'
                  : ''
              "
            >
              <!-- <div
                :class="getTaskClass(chapter, stage) ? 'boardTask boardTask-select' : 'boardTask '"
                @click="selectChange(getTask(chapter, stage).taskId, stage, stages,getTask(chapter, stage))"
                v-if="getTask(chapter, stage).taskId && filterCondition(getTask(chapter, stage))"
                :style="getTaskStyle(getTask(chapter, stage), chapter)"
              > -->
              <div
                :class="
                  chapter.stageIdTask &&
                  chapter.stageIdTask[stage.stageId] &&
                  chapter.stageIdTask[stage.stageId].isSelect
                    ? 'boardTask boardTask-select'
                    : 'boardTask '
                "
                @click="selectChange(getTask(chapter, stage), stage, stages, chapter.stageIdTask[stage.stageId])"
                v-if="getTask(chapter, stage).taskId && filterCondition(getTask(chapter, stage))"
                :style="getTaskStyle(getTask(chapter, stage), chapter)"
              >
                <div class="boardProduct">
                  <!--                 <a-checkbox
                  v-if="isSelect"
                  @click.stop
                  v-model="chapter.stageIdTask[stage.stageId].checked"
                  @change="
                    e => {
                      onChange(e, getTask(chapter, stage), stage, stages)
                    }
                  "
                  :disabled="isDis(getTask(chapter, stage), chapter, stage)"
                >
                  {{ parent.productionName }}
                </a-checkbox> -->
                  <div
                    @click.stop="gotoDetail(getTask(chapter, stage))"
                    class="text-omit-one-line productTitle kanban-span"
                  >
                    {{ parent.productionName }}
                  </div>
                  <StatusPointer
                    style="margin-top:5px"
                    :loadData="reloadData"
                    :parent="parent"
                    :stage="stage"
                    :chapter="chapter"
                    :stages="stages"
                    :task="getTask(chapter, stage)"
                  />
                </div>
                <div class="boardChapter kanban-span">
                  <div>{{ chapter.sequenceNo }}</div>
                </div>
                <div v-if="getIsEdit(chapter, stage)" class="boardUser kanban-span" @click.stop>
                  <a-select
                    showArrow
                    placeholder="选择用户"
                    style="margin-top:10px;width:97%"
                    @change="
                      e => {
                        userSelectChange(e, getTask(chapter, stage))
                      }
                    "
                    :defaultValue="chapter.stageIdTask && chapter.stageIdTask[stage.stageId].ownerId"
                  >
                    <a-select-option v-for="user in stage.users" :key="user.userId" :value="user.userId">
                      {{ user.userName }}
                    </a-select-option>
                  </a-select>
                </div>
                <div v-else class="boardUser kanban-span">
                  <img class="boardAvatar" :src="getTask(chapter, stage).avatar" />
                  <div>{{ getTask(chapter, stage).userName }}</div>
                </div>
                <div class="boardDeadline" v-if="getIsEdit(chapter, stage)" @click.stop>
                  <a-date-picker
                    class="kanban-date"
                    style="font-size:12px;margin-top:10px"
                    format="YYYY-MM-DD HH:mm"
                    valueFormat="YYYY-MM-DD HH:mm"
                    @click.stop
                    @change="
                      e => {
                        dateChange(e, getTask(chapter, stage))
                      }
                    "
                    :show-time="{ format: 'HH:mm', defaultValue: moment('23:59', 'HH:mm') }"
                    placeholder="请选择截止日期"
                    :defaultValue="getTask(chapter, stage).deadline"
                  />
                </div>
                <div class="boardDeadline" v-else-if="!getIsEdit(chapter, stage) && getTask(chapter, stage).deadline">
                  <div
                    :style="{ color: getTask(chapter, stage).warnStatus == 'DELAY' ? 'red' : null, lineHeight: '24px' }"
                  >
                    {{ getTask(chapter, stage).deadline.substring(2, 16) }}
                  </div>
                  <div class="more-icon" :style="backCss" @click.stop>
                    <!--                     <a-tooltip
                      v-if="
                        ((getTask(chapter, stage).taskStatus != 'WAITING' &&
                          getTask(chapter, stage).taskStatus != 'REJECTING') ||
                          (getTask(chapter, stage).taskStatus == 'REJECTING' &&
                            getTask(chapter, stage).rejectTaskId)) &&
                          getTask(chapter, stage).applyTranslation &&
                          getTask(chapter, stage).applyReview
                      "
                      transitionName=""
                    >
                      <template slot="title">
                        <span
                          v-if="
                            getTask(chapter, stage).stageName == '翻译' ||
                              getTask(chapter, stage).stageName == '终审' ||
                              getTask(chapter, stage).stageName == '校对'
                          "
                          >进入翻译系统</span
                        >
                        <span v-else>进入查稿系统</span>
                      </template>
                      <a-dropdown-button
                        size="small"
                        :trigger="['click']"
                        @click="
                          getTask(chapter, stage).stageName == '翻译' ||
                          getTask(chapter, stage).stageName == '终审' ||
                          getTask(chapter, stage).stageName == '校对'
                            ? goToTranslate(getTask(chapter, stage))
                            : goToFatchStraw(getTask(chapter, stage))
                        "
                      >
                        <a-menu slot="overlay">
                          <a-menu-item
                            key="2"
                            @click="
                              getTask(chapter, stage).stageName != '翻译' &&
                              getTask(chapter, stage).stageName != '终审' &&
                              getTask(chapter, stage).stageName != '校对'
                                ? goToTranslate(getTask(chapter, stage))
                                : goToFatchStraw(getTask(chapter, stage))
                            "
                          >
                            <a-tooltip transitionName="">
                              <template slot="title">
                                <span
                                  v-if="
                                    getTask(chapter, stage).stageName != '翻译' &&
                                      getTask(chapter, stage).stageName != '终审' &&
                                      getTask(chapter, stage).stageName != '校对'
                                  "
                                  >进入翻译系统</span
                                >
                                <span v-else>进入查稿系统</span>
                              </template>
                              <span
                                v-if="
                                  getTask(chapter, stage).stageName != '翻译' &&
                                    getTask(chapter, stage).stageName != '终审' &&
                                    getTask(chapter, stage).stageName != '校对'
                                "
                                class="iconfont icon-fanyi icon"
                                style="color:rgba(0,0,0,0.65);font-size:12px"
                              ></span>
                              <checklist v-else theme="outline" size="12" :fill="checkColor" :strokeWidth="3" />
                            </a-tooltip>
                          </a-menu-item>
                        </a-menu>
                        <span
                          v-if="
                            getTask(chapter, stage).stageName == '翻译' ||
                              getTask(chapter, stage).stageName == '终审' ||
                              getTask(chapter, stage).stageName == '校对'
                          "
                          class="iconfont icon-fanyi icon"
                          style="color:rgba(0,0,0,0.65);font-size:12px"
                        ></span>
                        <checklist
                          theme="outline"
                          v-else
                          size="12"
                          :fill="checkColor"
                          @click="goToFatchStraw(getTask(chapter, stage))"
                          :strokeWidth="3"
                        />
                        <a-icon style="font-size:12px" type="down" @click.stop slot="icon" />
                      </a-dropdown-button>
                    </a-tooltip>
                    <a-tooltip
                      v-else-if="
                        ((getTask(chapter, stage).taskStatus != 'WAITING' &&
                          getTask(chapter, stage).taskStatus != 'REJECTING') ||
                          (getTask(chapter, stage).taskStatus == 'REJECTING' &&
                            getTask(chapter, stage).rejectTaskId)) &&
                          getTask(chapter, stage).applyTranslation &&
                          !getTask(chapter, stage).applyReview
                      "
                      transitionName=""
                    >
                      <template slot="title">
                        <span>进入翻译系统</span>
                      </template>
                      <a-button size="small" @click="goToTranslate(getTask(chapter, stage))">
                        <span class="iconfont icon-fanyi icon" style="color:rgba(0,0,0,0.65);font-size:12px"></span>
                      </a-button>
                    </a-tooltip>
                    <a-tooltip
                      v-else-if="
                        ((getTask(chapter, stage).taskStatus != 'WAITING' &&
                          getTask(chapter, stage).taskStatus != 'REJECTING') ||
                          (getTask(chapter, stage).taskStatus == 'REJECTING' &&
                            getTask(chapter, stage).rejectTaskId)) &&
                          getTask(chapter, stage).applyReview &&
                          !getTask(chapter, stage).applyTranslation
                      "
                      transitionName=""
                    >
                      <template slot="title">
                        <span>进入查稿系统</span>
                      </template>
                      <a-button size="small" @click="goToFatchStraw(getTask(chapter, stage))">
                        <checklist
                          style="position:relative;top:1px;left:1px"
                          theme="outline"
                          size="12"
                          :fill="checkColor"
                          :strokeWidth="3"
                        />
                      </a-button>
                    </a-tooltip> -->
                    <a-dropdown
                      :trigger="['click']"
                      v-if="
                        (((getTask(chapter, stage).taskStatus != 'WAITING' &&
                          getTask(chapter, stage).taskStatus != 'REJECTING') ||
                          (getTask(chapter, stage).taskStatus == 'REJECTING' &&
                            getTask(chapter, stage).rejectTaskId)) &&
                          (getTask(chapter, stage).applyTranslation || getTask(chapter, stage).applyReview)) ||
                          (getTask(chapter, stage).applyReview &&
                            (getTask(chapter, stage).stageName == '制作' ||
                              getTask(chapter, stage).stageName == '制作修改' ||
                              getTask(chapter, stage).stageName == '监制' ||
                              getTask(chapter, stage).stageName == '监制确认')) ||
                          getTask(chapter, stage).stageName == '给稿' ||
                          (getTask(chapter, stage).applyTranslation && getTask(chapter, stage).stageName == '制作') ||
                          (getTask(chapter, stage).applyReview &&
                            (getTask(chapter, stage).stageName == '监制确认' ||
                              getTask(chapter, stage).stageName == '监制' ||
                              getTask(chapter, stage).stageName == '交稿')) ||
                          (getTask(chapter, stage).applyTranslation && getTask(chapter, stage).stageName == '交稿')
                      "
                    >
                      <a-icon
                        type="dash"
                        class="more-icon"
                        style="position:relative;left:-7px;top:2px;font-weight:bold;color:black"
                      />
                      <a-menu slot="overlay">
                        <a-menu-item
                          key="1"
                          @click="goToTranslate(getTask(chapter, stage))"
                          v-if="
                            ((getTask(chapter, stage).taskStatus != 'WAITING' &&
                              getTask(chapter, stage).taskStatus != 'REJECTING') ||
                              (getTask(chapter, stage).taskStatus == 'REJECTING' &&
                                getTask(chapter, stage).rejectTaskId)) &&
                              getTask(chapter, stage).applyTranslation
                          "
                        >
                          <span>跳转翻译系统</span>
                        </a-menu-item>
                        <a-menu-item
                          key="2"
                          @click="goToFatchStraw(getTask(chapter, stage))"
                          v-if="
                            ((getTask(chapter, stage).taskStatus != 'WAITING' &&
                              getTask(chapter, stage).taskStatus != 'REJECTING') ||
                              (getTask(chapter, stage).taskStatus == 'REJECTING' &&
                                getTask(chapter, stage).rejectTaskId)) &&
                              getTask(chapter, stage).applyReview
                          "
                        >
                          <span>跳转查稿系统</span>
                        </a-menu-item>
                        <a-menu-item
                          key="3"
                          @click="goToUpload(getTask(chapter, stage))"
                          v-if="getTask(chapter, stage).stageName == '给稿'"
                        >
                          <span>上传原稿</span>
                        </a-menu-item>
                        <a-menu-item
                          key="4"
                          @click="gotoSumbmit(getTask(chapter, stage))"
                          v-if="
                            getTask(chapter, stage).applyReview &&
                              (getTask(chapter, stage).stageName == '制作' ||
                                getTask(chapter, stage).stageName == '制作修改' ||
                                getTask(chapter, stage).stageName == '监制' ||
                                getTask(chapter, stage).stageName == '监制确认' ||
                                getTask(chapter, stage).stageName.indexOf('建模') > -1 ||
                                getTask(chapter, stage).stageName.indexOf('精草') > -1 ||
                                getTask(chapter, stage).stageName.indexOf('勾线') > -1 ||
                                getTask(chapter, stage).stageName.indexOf('上色') > -1 ||
                                getTask(chapter, stage).stageName.indexOf('后期') > -1)
                          "
                        >
                          <span>上传完成稿</span>
                        </a-menu-item>
                        <a-menu-item
                          key="5"
                          @click="downloadMake(getTask(chapter, stage))"
                          v-if="
                            getTask(chapter, stage).systemTypes.some(item => item == 'IMAGE_TRANSLATION_SYSTEM') &&
                              getTask(chapter, stage).stageName == '制作'
                          "
                        >
                          <span>下载制作稿</span>
                        </a-menu-item>
                        <a-menu-item
                          key="6"
                          @click="downloadOver(getTask(chapter, stage), false)"
                          v-if="
                            getTask(chapter, stage).applyReview &&
                              (getTask(chapter, stage).stageName == '监制确认' ||
                                getTask(chapter, stage).stageName == '监制' ||
                                getTask(chapter, stage).stageName == '交稿' ||
                                getTask(chapter, stage).stageName == '勾线终审' ||
                                getTask(chapter, stage).stageName == '上色终审')
                          "
                        >
                          <span>下载终稿</span>
                        </a-menu-item>
                        <a-menu-item
                          key="7"
                          @click="downloadOver(getTask(chapter, stage), true)"
                          v-if="
                            getTask(chapter, stage).applyReview &&
                              (getTask(chapter, stage).stageName == '监制确认' ||
                                getTask(chapter, stage).stageName == '监制' ||
                                getTask(chapter, stage).stageName == '交稿')
                          "
                        >
                          <span>下载切片</span>
                        </a-menu-item>
                        <a-menu-item
                          key="8"
                          @click="downloadJpg(getTask(chapter, stage))"
                          v-if="getTask(chapter, stage).applyTranslation && getTask(chapter, stage).stageName == '交稿'"
                        >
                          <span>下载翻译JPG</span>
                        </a-menu-item>
                        <a-menu-item
                          key="9"
                          @click="downloadPdf(getTask(chapter, stage))"
                          v-if="getTask(chapter, stage).applyTranslation && getTask(chapter, stage).stageName == '交稿'"
                        >
                          <span>下载翻译PDF</span>
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="virtual-bottom" :style="'height:' + bottomLength + 'px'"></div>
      </div>
    </div>
    <a-modal title="请更换人员" width="350px" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <a-select
        showArrow
        placeholder="选择用户"
        v-model="ownerId"
        option-filter-prop="label"
        @click.stop
        style="width:250px;"
      >
        <a-select-option :label="user.userName" v-for="user in selectUsers" :key="user.userId" :value="user.userId">
          <img
            style="width:25px;height:25px;border-radius:50%;margin-right:10px;position: relative;top: -2px;"
            :src="user.avatar"
            alt=""
          />
          <span>{{ user.userName }}</span>
        </a-select-option>
      </a-select>
    </a-modal>
    <a-modal
      :visible="confirmVisible"
      wrapClassName="confirm-modal"
      :bodyStyle="{ padding: '32px', paddingBottom: '24px' }"
      width="416px"
      :footer="null"
      @cancel="confirmVisible = false"
    >
      <div style="font-size:16px;display: flex;align-items: center;font-weight:500;color:rgba(0, 0, 0, 0.85)">
        <a-icon
          type="question-circle"
          style="font-size: 22px;margin-right:15px; color:orange;"
        />是否加载已锁定状态的文件?
      </div>
      <div style="height:62px;display: flex;align-items: end;justify-content: end;">
        <a-button @click="handleConfimCancel(confirmTask)">
          不加载
        </a-button>
        <a-button type="primary" style="margin-left:8px;" @click="handleConfimOk(confirmTask)">
          加载
        </a-button>
      </div>
    </a-modal>
  </a-spin>
</template>

<script>
import { getAction, postAction, v1postAction } from '@/api/manage'
import StatusPointer from '@/components/task/StatusPointer'
import { checkPermission } from '@/utils/hasPermission'
import VirtualList from 'vue-virtual-scroll-list'
import { simpleDebounce } from '@/utils/util'
import moment from 'moment'
import { Checklist } from '@icon-park/vue'
import { axios } from '@/utils/request'
import Vue from 'vue'
import { USER_INFO, ACCESS_TOKEN } from '@/store/mutation-types'
export default {
  components: {
    Checklist,
    StatusPointer,
    VirtualList
  },
  created() {
    console.log('task-board created', { parent })
    this.reloadData()
  },
  props: {
    parent: {
      type: Object,
      default: () => ({})
    },
    isMyTask: {
      type: Boolean,
      default: false
    },
    selectKeys: {
      type: Array,
      default: () => []
    },
    filterValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chapters: [],
      downProgress: 0,
      eidtDis: false,
      checkColor: 'rgba(0, 0, 0, 0.55)',
      stages: [],
      current: 1,
      // size -1表示不分页
      size: -1,
      loading: false,
      isLastPage: false,
      confirmVisible: false,
      confirmTask: {},
      isEdit: false,
      isSelect: false,
      saveData: [],
      allUsers: [],
      copyChapters: [],
      topLength: 0,
      bottomLength: 0,
      scrollNum: 0,
      showNum: 5,
      eidtDis: false,
      oldTop: 0,
      isBottom: false,
      color: '',
      backColor: '',
      visible: false,
      ownerId: undefined,
      selectUsers: [],
      checkedList: [],
      stagesCopy: [],
      isSort: false
    }
  },
  computed: {
    backCss() {
      this.color = this.$store.state.app.color
      return {
        '--theme-color': this.color,
        '--back-color': this.backColor
      }
    }
  },
  mounted() {
    document.getElementsByClassName('boardContent')[0].addEventListener('scroll', this.handleScroll)
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.backColor = rule.style.backgroundColor
            localStorage.setItem(
              'color',
              JSON.stringify({ hoverColor: this.color, color: this.$store.state.app.color })
            )
          }
        }
      }
    }, 50)
    this.isSort = false
  },
  watch: {
    scrollNum(n, o) {
      this.copyChapters = this.chapters.filter((item, index) => {
        return index < this.showNum + n && index >= n
      })
      if (n > o && n < this.chapters.length - this.showNum) {
        let height = n * 140
        this.topLength = height
        if (this.bottomLength) this.bottomLength = this.bottomLength - 140
      } else if ((n <= o && n < this.chapters.length - this.showNum) || this.isBottom) {
        let height = n * 140
        this.topLength = height
        this.bottomLength = this.bottomLength + 140
        if (this.isBottom) {
          this.bottomLength = this.bottomLength - 140
        }
      }
      if (n >= this.chapters.length - this.showNum && !this.isBottom) {
        this.changeBottom()
      }
    }
  },
  methods: {
    moment,
    downloadJpg(taskDetail) {
      localStorage.setItem('translate_download', 'JPG')
      /* window.open(`/translate/${taskDetail.taskId}/${taskDetail.chapterId}/0/0`) */
      const newUrl = `/translate/${taskDetail.taskId}/${taskDetail.chapterId}/0/0`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    downloadPdf(taskDetail) {
      localStorage.setItem('translate_download', 'PDF')
      /* window.open(`/translate/${taskDetail.taskId}/${taskDetail.chapterId}/0/0`) */
      const newUrl = `/translate/${taskDetail.taskId}/${taskDetail.chapterId}/0/0`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    changeBottom: simpleDebounce(async function() {
      this.changeBottomSim()
    }, 50),
    changeBottomSim() {
      this.isBottom = true
      this.bottomLength = 0
      this.topLength = (this.chapters.length - this.showNum) * 140
    },
    getIsEdit(chapter, stage) {
      if (chapter.stageIdTask[stage.stageId]) {
        return chapter.stageIdTask[stage.stageId].isEdit
      } else {
        return false
      }
    },
    async downloadOver(data, isSection) {
      let arr = [data]
      let that = this
      arr.forEach(async (item, index) => {
        let productOrder = item.productOrder + ''
        let chapterOrder = item.chapterOrder + ''
        if (productOrder.length == 1) {
          productOrder = '00' + productOrder
        } else if (productOrder.length == 2) {
          productOrder = '0' + productOrder
        }
        if (chapterOrder.length == 1) {
          chapterOrder = '00' + chapterOrder
        } else if (chapterOrder.length == 2) {
          chapterOrder = '0' + chapterOrder
        }
        const token = Vue.ls.get(ACCESS_TOKEN)
        const res = await axios.post(
          Vue.prototype.API_BASE_URL +
            '/finish/manuscript/download?chapterId=' +
            item.chapterId +
            '&isSection=' +
            isSection,
          {},
          {
            headers: { 'X-Access-Token': token },
            responseType: 'blob',
            onDownloadProgress(progress) {
              // 监听下载进度的方法
              that.downProgress = Math.round((100 * progress.loaded) / progress.total)
              if (progress.total) {
                if (!open) {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: isSection
                      ? '下载切片-' + productOrder + '-' + item.productionName + '-' + chapterOrder
                      : '下载终稿-' + productOrder + '-' + item.productionName + '-' + chapterOrder,
                    duration: that.downProgress == 100 ? 0.3 : null,
                    description: h => {
                      return h('div', null, [
                        h(
                          'a-row',
                          {
                            props: {
                              type: 'flex'
                            },
                            attrs: {}
                          },
                          [
                            h(
                              'a-col',
                              {
                                props: {
                                  flex: 'auto'
                                }
                              },
                              [
                                h('a-progress', {
                                  props: {
                                    percent: that.downProgress,
                                    showInfo: false
                                  },
                                  attrs: {
                                    style: 'width:55%'
                                  }
                                }),
                                h(
                                  'span',
                                  {
                                    attrs: {
                                      style: 'color: rgba(0,0,0,.45);font-size: 1em;margin-left: 12px;'
                                    }
                                  },
                                  `${that.downProgress}%（${(progress.loaded / 1024 / 1024).toFixed(2)}/${(
                                    progress.total /
                                    1024 /
                                    1024
                                  ).toFixed(2)}M）`
                                )
                              ]
                            ),
                            h('a-col', {
                              props: {
                                flex: '38px'
                              },
                              attrs: {
                                style: 'margin-top:2px;'
                              }
                            })
                          ]
                        )
                      ])
                    }
                  })
                } else {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: isSection
                      ? '下载切片-' + productOrder + '-' + item.productionName + '-' + chapterOrder
                      : '下载终稿-' + productOrder + '-' + item.productionName + '-' + chapterOrder,
                    description: h => {
                      return h('div', null, [
                        h(
                          'a-row',
                          {
                            props: {
                              type: 'flex'
                            },
                            attrs: {}
                          },
                          [
                            h(
                              'a-col',
                              {
                                props: {
                                  flex: 'auto'
                                }
                              },
                              [
                                h('a-progress', {
                                  props: {
                                    percent: that.downProgress,
                                    showInfo: false
                                  },
                                  attrs: {
                                    style: 'width:55%'
                                  }
                                }),
                                h(
                                  'span',
                                  {
                                    attrs: {
                                      style: 'color: rgba(0,0,0,.45);font-size: 1em;margin-left: 12px;'
                                    }
                                  },
                                  `${that.downProgress}%（${(progress.loaded / 1024 / 1024).toFixed(2)}/${(
                                    progress.total /
                                    1024 /
                                    1024
                                  ).toFixed(2)}M）`
                                )
                              ]
                            ),
                            h('a-col', {
                              props: {
                                flex: '38px'
                              },
                              attrs: {
                                style: 'margin-top:2px;'
                              }
                            })
                          ]
                        )
                      ])
                    },
                    duration: that.downProgress == 100 ? 0.3 : null
                  })
                }
              } else {
                if (!open) {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: '下载终稿-' + productOrder + '-' + item.productionName + '-' + chapterOrder,
                    duration: null,
                    description: '已下载' + (progress.loaded / 1024 / 1024).toFixed(2) + 'M'
                  })
                } else {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: '下载终稿-' + productOrder + '-' + item.productionName + '-' + chapterOrder,
                    description: '已下载' + (progress.loaded / 1024 / 1024).toFixed(2) + 'M',
                    duration: null
                  })
                }
              }
              open = true
              console.log(that.downProgress, progress.loaded, progress.total, 123)
            }
          }
        )
        if (!res) return
        const blob = res
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        let fileName = item.productionName + '-' + chapterOrder + '-终稿'
        if (data.departName.indexOf('原创部') == -1) {
          fileName = productOrder + '-' + chapterOrder
        }
        a.href = url
        a.download = fileName + '.zip'
        a.click()
        window.URL.revokeObjectURL(url)
        /*         this.$notification.open({
          key: 'downLoad' + index,
          message: '下载终稿-'+productOrder+'-'+item.productionName + '-' + chapterOrder,
          description: '已下载' + that.downProgress + 'M',
          duration: 0.3
        }) */
      })
    },
    async downloadMake(taskDetail) {
      let that = this
      this.loading = true
      const rest = await v1postAction('/psd/get_chapter_make_psd_link', {
        productionId: taskDetail.productionId,
        chapterId: taskDetail.chapterId
      })
      if (rest.code == 200) {
        let postData = { list: [] }
        rest.data?.forEach(data => {
          let makeArr = []
          data.textAreas.forEach(textArea => {
            if (!textArea.characterSentences.length) return
            let textArr = textArea.showText.split('\n')
            var reg = /^[a-zA-Z]+$/

            textArr = textArr.sort((a, b) => a.replaceAll('.', '').length - b.replaceAll('.', '').length)
            let textArrLength = 0
            textArr[textArr.length - 1].split('').forEach(text => {
              if (reg.test(text) || text == ' ') {
                textArrLength = textArrLength + 0.5
              } else if (text == '.') {
                textArrLength = textArrLength + 0.3
              } else {
                textArrLength++
              }
            })
            textArrLength = textArrLength.toFixed(0) - 0
            let maxWidth =
              (
                textArrLength * textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize
              ).toFixed(0) - 0
            if (textArea.textLayout == 2) {
              maxWidth =
                (
                  textArr.length *
                  textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize *
                  textArea.characterSentences[textArea.characterSentences.length - 1].font.lineSpacing
                ).toFixed(0) - 0
            }
            let maxHeight =
              (
                textArr.length *
                textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize *
                textArea.characterSentences[textArea.characterSentences.length - 1].font.lineSpacing
              ).toFixed(0) - 0
            if (textArea.textLayout == 2) {
              maxHeight =
                (
                  textArrLength * textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize
                ).toFixed(0) - 0
            }
            let changeWidth = ((maxWidth - textArea.textAreaTranslationRegion.width) / 2).toFixed(0) - 0
            let changeHeight = ((maxHeight - textArea.textAreaTranslationRegion.height) / 2).toFixed(0) - 0
            let pushArr = []
            let brIndex = [-1]
            let newBrIndex = [-1]
            let changeBrIndex = []
            let phoneticArr = []
            let pushPhoneticText = ''
            let theX = 0
            let theY = 0
            let phoneticBrIndexOftext = []
            let arr = []
            textArea.showTexts = []
            textArea.characterSentences.forEach((text, textIndex) => {
              if (text.characterText == '\n') text.characterText = '<br>'
              if (text.characterText == '<br>') newBrIndex.push(textIndex)
              if (text.phonetic && text.phonetic.phoneticText) {
                phoneticBrIndexOftext.push(newBrIndex[newBrIndex.length - 1])
              }
              if (text.characterText != '<br>') {
                arr.push(text)
              } else {
                textArea.showTexts.push({ pId: '', texts: arr, textIndex })
                arr = []
                if (
                  textIndex == textArea.characterSentences.length - 1 &&
                  !arr.length &&
                  text.characterText == '<br>'
                ) {
                  textArea.showTexts.push({
                    pId: '',
                    texts: [
                      {
                        ...textArea.characterSentences[0],
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      }
                    ],
                    pushTtextIndex: textIndex
                  })
                }
              }
              if (textIndex == textArea.characterSentences.length - 1 && arr.length) {
                textArea.showTexts.push({ pId: '', texts: arr, pushTtextIndex: textIndex })
              }
            })
            textArea.showTexts = textArea.showTexts.sort((a, b) => b.texts.length - a.texts.length)
            phoneticBrIndexOftext = Array.from(new Set(phoneticBrIndexOftext))
            let enNum = 0
            textArea.characterSentences.forEach((text, textIndex) => {
              let markArr = []
              if (text.characterText == '<br>') {
                brIndex.push(textIndex)
                enNum = 0
              }
              let minusWidth = 0
              /*               if (changeWidth < 0) {
                if (phoneticBrIndexOftext.length) {
                  minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
                } else {
                  minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
                }
              } */
              minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
              let textAlignWidth = 0
              if (text.font.textAlign == 'right' && textArea.showTexts[0]) {
                textAlignWidth = textArea.showTexts[0].texts.length * text.font.fontSize
              }
              theX =
                textArea.textLayout == 1
                  ? textArea.textAreaTranslationRegion.x - changeWidth + textAlignWidth
                  : textArea.textAreaTranslationRegion.x -
                    changeWidth +
                    phoneticBrIndexOftext.length * ((text.font.fontSize - 1) / 2 + 1) +
                    minusWidth
              theY =
                textArea.textLayout == 1
                  ? textArea.textAreaTranslationRegion.y - changeHeight + text.font.fontSize
                  : textArea.textAreaTranslationRegion.y - changeHeight + textAlignWidth
              let pushCharacterText = text.characterText == '<br>' ? '\r' : text.characterText
              if (
                textArea.characterSentences[textIndex - 1] &&
                textArea.characterSentences[textIndex - 1].characterText == '<br>' &&
                textArea.characterSentences[textIndex].characterText == ' '
              ) {
                pushCharacterText = ''
              }
              let textPushArr = [
                pushCharacterText == '\n' ? '\r' : pushCharacterText,
                (text.font.fontSize - 1) / 4 - 0,
                text.font.postScriptName,
                { red: text.font.fontColor.red, green: text.font.fontColor.green, blue: text.font.fontColor.blue },
                { bold: text.font.isBold ? true : false, italic: text.font.isIncline ? true : false },
                text.font.textAlign,
                0.0,
                (text.font.lineSpacing * 100).toFixed(0) - 0,
                0.0,
                textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                theX,
                theY,
                text.font.strokeWidth || 0,
                text.font.strokeColor
                  ? {
                      red: text.font.strokeColor.red,
                      green: text.font.strokeColor.green,
                      blue: text.font.strokeColor.blue
                    }
                  : { red: 255, green: 255, blue: 255 },
                'antiAliasStrong',
                textArea.textLayout == 1 ? 0 : 1,
                0,
                text.characterSentenceId
              ]
              if (reg.test(text.characterText)) {
                if (
                  text.characterText == 'f' ||
                  text.characterText == 'i' ||
                  text.characterText == 'j' ||
                  text.characterText == 'l' ||
                  text.characterText == 't'
                ) {
                  enNum = enNum + 0.5
                } else {
                  if (text.characterText == 'A') {
                    enNum = enNum + 0.25
                  } /* else if(text.characterText=='B'){
                    enNum = enNum + 0.3
                  } */ else {
                    enNum = enNum + 0.3
                  }
                }
              }
              if (text.font.hasMark) {
                let markText = '.'
                let markX = 0
                if (!brIndex.length) {
                  markX = (textIndex - 1 - enNum) * text.font.fontSize + (text.font.fontSize / 2 - 0) - 3
                  if (textIndex > 8) {
                    markX = markX + ((textIndex - 8) / 3) * 2
                  }
                  if (text.font.isBold) {
                    markX = markX + textIndex
                  }
                } else {
                  markX =
                    (textIndex - brIndex[brIndex.length - 1] - 1 - enNum) * text.font.fontSize +
                    (text.font.fontSize / 2 - 0) -
                    3
                  if (textIndex - brIndex[brIndex.length - 1] > 8) {
                    markX = markX + ((textIndex - brIndex[brIndex.length - 1] - 8) / 3) * 2
                  }
                  if (text.font.isBold) {
                    markX = markX + (textIndex - brIndex[brIndex.length - 1])
                  }
                }
                if (text.font.textAlign == 'right') {
                  markX = theX - markX
                }
                let markPushArr = [
                  markText,
                  ((text.font.fontSize - 1) / 4).toFixed(0) - 0,
                  text.font.postScriptName,
                  { red: text.font.fontColor.red, green: text.font.fontColor.green, blue: text.font.fontColor.blue },
                  { bold: text.font.isBold ? true : false, italic: text.font.isIncline ? true : false },
                  text.font.textAlign,
                  0.0,
                  (text.font.lineSpacing * 100).toFixed(0) - 0,
                  0.0,
                  textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                  textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                  text.font.textAlign == 'right' ? markX : theX + markX,
                  theY +
                    (brIndex.length - 2) +
                    (text.font.fontSize - 1) / 4 +
                    (((brIndex.length - 1) * text.font.fontSize * text.font.lineSpacing).toFixed(0) - 0),
                  text.font.strokeWidth || 0,
                  text.font.strokeColor
                    ? {
                        red: text.font.strokeColor.red,
                        green: text.font.strokeColor.green,
                        blue: text.font.strokeColor.blue
                      }
                    : { red: 255, green: 255, blue: 255 },
                  'antiAliasStrong',
                  textArea.textLayout == 1 ? 0 : 1,
                  0,
                  text.characterSentenceId
                ]
                markArr.push(markPushArr)
                makeArr.push(markArr)
              }
              pushArr.push(textPushArr)
              if (text.phonetic && text.phonetic.phoneticText) {
                if (text.phonetic.font) {
                  text.phonetic.font.postScriptName = text.font.postScriptName
                }
                let isFirstText = false
                if (!textIndex || textArea.characterSentences[textIndex - 1].characterText == '<br>') isFirstText = true
                phoneticArr.push({
                  targetCharacterIds: text.phonetic.targetCharacterIds,
                  brNum: brIndex.length - 1,
                  phonetic: text.phonetic,
                  text,
                  textIndex,
                  isFirstText
                })
              }
              if (
                phoneticArr.findIndex(p => p.textIndex == textIndex) > -1 &&
                phoneticArr.find(p => p.textIndex == textIndex).isFirstText &&
                phoneticArr.find(p => p.textIndex == textIndex).phonetic.phoneticText.length > 3
              ) {
                pushPhoneticText = ''
                let pushPhoneticTextLength = phoneticArr.find(p => p.textIndex == textIndex).phonetic.phoneticText
                  .length
                for (let i = 0; i < pushPhoneticTextLength - 3; i++) {
                  pushPhoneticText = pushPhoneticText + ' '
                }
              }
            })
            if (brIndex.length && phoneticArr.length) {
              brIndex.forEach((brIdx, brIdxIndex) => {
                brIdx = brIdx + 1 + brIdxIndex
                pushArr.splice(brIdx, 0, [
                  pushPhoneticText,
                  (phoneticArr[0].phonetic.font.fontSize - 1) / 4 - 0,
                  pushArr[0][2],
                  pushArr[0][3],
                  pushArr[0][4],
                  pushArr[0][5],
                  pushArr[0][6],
                  pushArr[0][7],
                  pushArr[0][8],
                  pushArr[0][9],
                  pushArr[0][10],
                  theX,
                  theY,
                  pushArr[0][13],
                  pushArr[0][14],
                  pushArr[0][15],
                  textArea.textLayout == 1 ? 0 : 1,
                  pushArr[0][17]
                ])
                changeBrIndex.push(brIdx - 1)
              })
            }
            if (phoneticArr.length) {
              let phoneticBrIndex = [...changeBrIndex]
              let pushPhoneticBrIndexArr = []
              let pushPhoneticBrNum = 0
              phoneticArr.forEach((p, pIdx) => {
                if (pushPhoneticBrIndexArr.indexOf(changeBrIndex[p.brNum]) > -1) {
                  return
                }
                pushArr.splice(changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum, 0, [
                  '\r',
                  pushArr[0][1],
                  pushArr[0][2],
                  pushArr[0][3],
                  pushArr[0][4],
                  pushArr[0][5],
                  pushArr[0][6],
                  pushArr[0][7],
                  pushArr[0][8],
                  pushArr[0][9],
                  pushArr[0][10],
                  theX,
                  theY,
                  pushArr[0][13],
                  pushArr[0][14],
                  pushArr[0][15],
                  textArea.textLayout == 1 ? 0 : 1,
                  pushArr[0][17]
                ])
                /*                 changeBrIndex.forEach(c => {
                  if (
                    pushArr[c + pushPhoneticBrNum] &&
                    pushArr[c + pushPhoneticBrNum - 1] &&
                    pushArr[c + pushPhoneticBrNum][0] == '\r' &&
                    pushArr[c + pushPhoneticBrNum - 1][0] == '\r'
                  ) {
                    phoneticBrIndex.push(c == -1 ? 0 : c + pushPhoneticBrNum - 1)
                  } else {
                    phoneticBrIndex.push(c == -1 ? 0 : c + pushPhoneticBrNum)
                  }
                }) */
                let pushBrNum = changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum
                phoneticBrIndex[p.brNum] = pushBrNum
                /* phoneticBrIndex.push(changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum) */
                pushPhoneticBrIndexArr.push(changeBrIndex[p.brNum])
                pushPhoneticBrNum++
              })
              phoneticArr = phoneticArr.reverse()
              let phoneticSzie = 0
              let moveNum = 0
              brIndex = []
              pushArr.forEach((text, textIndex) => {
                if (
                  text[0] == '\r' &&
                  ((pushArr[textIndex - 1] && pushArr[textIndex - 1][0] == '\r') ||
                    (pushArr[textIndex + 1] && pushArr[textIndex + 1][0] != '\r') ||
                    !textIndex)
                ) {
                  /* if(pushArr[textIndex - 1] && pushArr[textIndex - 1][0] == '\r'&&pushArr[textIndex] && pushArr[textIndex][0] == '\r'){
                    brIndex.push(textIndex)
                  } */
                  brIndex.push(textIndex)
                }
              })
              phoneticArr.forEach((p, pIdx) => {
                if (!p.targetCharacterIds) p.targetCharacterIds = []
                let menuis = brIndex[0] == 0 ? 0 : 1
                moveNum = pushArr.findIndex(text => text[18] == p.targetCharacterIds[0]) - brIndex[p.brNum - menuis] - 1
                /* textArea.showText
                  .split('\n')
                  [p.brNum].split('')
                  .forEach((t, tEnIdx) => {
                    if (
                      reg.test(t) &&
                      textArea.characterSentences.findIndex(te => te.characterSentenceId == p.targetCharacterIds[0]) >
                        tEnIdx
                    ) {
                      moveNum--
                    }
                  }) */
                p.moveNum = moveNum
              })
              let addTotal = phoneticArr.length
              let addArr = []
              phoneticArr.forEach((p, pIdx) => {
                phoneticSzie = (p.phonetic.font.fontSize - 1) / 4 - 0
                let sortNum = phoneticArr
                  .filter(cp => cp.brNum == p.brNum)
                  .sort((a, b) => {
                    return a.moveNum - b.moveNum
                  })
                let addNum = 1
                if (p.phonetic.phoneticText.length > 1) {
                  addNum = 0
                } else if (p.phonetic.phoneticText.length == 1) {
                  addNum = 2
                }
                if (p.phonetic.phoneticText.length == 3 && p.targetCharacterIds.length == 1) {
                  addNum = -2
                }
                console.log(p.moveWidth, p, sortNum, addNum)
                if (p.moveNum != sortNum[0].moveNum) {
                  if (/^[a-zA-Z]+$/.test(p.phonetic.phoneticText)) {
                    if (p.phonetic.phoneticText.length > 2) {
                      addNum = 0
                    } else if (p.phonetic.phoneticText.length == 2) {
                      addNum = 2
                    } else if (p.phonetic.phoneticText.length == 1) {
                      addNum = 3
                    }
                    if (p.phonetic.font.postScriptName && p.phonetic.font.postScriptName.indexOf('851') > -1)
                      addNum = addNum + 1
                    if (!p.phonetic.font.postScriptName) {
                      this.$message.error('注音：' + p.phonetic.phoneticText + '未获取到字体名称')
                    }
                  }
                  p.moveWidth =
                    (p.moveNum - sortNum[sortNum.findIndex(s => s.moveNum == p.moveNum) - 1].moveNum - 1) * 8 + addNum
                  let changeNum =
                    (sortNum[sortNum.findIndex(s => s.moveNum == p.moveNum) - 1].targetCharacterIds.length - 1) * 4
                  /*                     sortNum.forEach((s,sIdx)=>{
                      if(sIdx<sortNum.findIndex(s => s.moveNum == p.moveNum)){
                        changeNum=changeNum+(s.targetCharacterIds.length-1)*3
                      }
                    }) */
                  p.moveWidth = p.moveWidth - changeNum
                  if (p.phonetic.phoneticText.length > 5 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = p.moveWidth - 1
                  }
                  if (p.targetCharacterIds.length > 1) {
                    p.moveWidth = p.moveWidth + (p.targetCharacterIds.length - 1) * 4
                  }
                } else {
                  if (p.phonetic.font.postScriptName && p.phonetic.font.postScriptName.indexOf('851') > -1)
                    addNum = addNum + (p.moveNum == 1 ? 2 : 1)
                  if (!p.phonetic.font.postScriptName) {
                    this.$message.error('注音：' + p.phonetic.phoneticText + '未获取到字体名称')
                  }
                  if (/^[a-zA-Z]+$/.test(p.phonetic.phoneticText)) addNum = 1
                  p.moveWidth = (p.moveNum - 2 + p.targetCharacterIds.length) * 10 + addNum - 2
                  /* if (p.phonetic.phoneticText.length == 4 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = p.moveWidth - 2
                  } */
                  /*  if (p.phonetic.phoneticText.length == 3 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = 1
                  }
                  if (p.phonetic.phoneticText.length == 2 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = 1
                  } */
                  if (p.moveNum == 1) {
                    p.moveWidth = (p.targetCharacterIds.length - 1) * 4 + 2
                  }
                }
                let addText = ''
                for (let i = 0; i < p.moveWidth; i++) {
                  addText = addText + ' '
                }
                addArr.push({
                  index: phoneticBrIndex[p.brNum] + (phoneticBrIndex[p.brNum] ? addTotal : addTotal - 1),
                  addText,
                  text: p.text,
                  p
                })
                addTotal--
                pushArr.splice(phoneticBrIndex[p.brNum] ? phoneticBrIndex[p.brNum] + 1 : 0, 0, [
                  p.phonetic.phoneticText,
                  (p.phonetic.font.fontSize - 1) / 4 - 0,
                  p.phonetic.font.postScriptName,
                  {
                    red: p.text.font.fontColor.red,
                    green: p.text.font.fontColor.green,
                    blue: p.text.font.fontColor.blue
                  },
                  { bold: p.phonetic.font.isBold ? true : false, italic: p.phonetic.font.isIncline ? true : false },
                  'center',
                  p.phonetic.font.letterSpacing,
                  (p.phonetic.font.lineSpacing * 100).toFixed(0) - 0,
                  0.0,
                  p.phonetic.font.textHeight * 100,
                  p.phonetic.font.textWidth * 100,
                  theX,
                  theY,
                  p.phonetic.font.strokeWidth || 0,
                  p.phonetic.font.strokeColor
                    ? {
                        red: p.phonetic.font.strokeColor.red,
                        green: p.phonetic.font.strokeColor.green,
                        blue: p.phonetic.font.strokeColor.blue
                      }
                    : { red: 255, green: 255, blue: 255 },
                  'antiAliasStrong',
                  textArea.textLayout == 1 ? 0 : 1,
                  1
                ])
              })
              addArr = addArr.reverse()
              addArr.forEach((a, aIdx) => {
                pushArr.splice(a.index + aIdx, 0, [
                  a.addText,
                  (a.p.phonetic.font.fontSize - 1) / 4 - 0,
                  'AdobeHeitiStd-Regular',
                  {
                    red: a.text.font.fontColor.red,
                    green: a.text.font.fontColor.green,
                    blue: a.text.font.fontColor.blue
                  },
                  { bold: a.p.phonetic.font.isBold ? true : false, italic: a.p.phonetic.font.isIncline ? true : false },
                  'center',
                  a.p.phonetic.font.letterSpacing,
                  (a.p.phonetic.font.lineSpacing * 100).toFixed(0) - 0,
                  0.0,
                  100,
                  100,
                  theX,
                  theY,
                  a.p.phonetic.font.strokeWidth || 0,
                  a.p.phonetic.font.strokeColor
                    ? {
                        red: a.p.phonetic.font.strokeColor.red,
                        green: a.p.phonetic.font.strokeColor.green,
                        blue: a.p.phonetic.font.strokeColor.blue
                      }
                    : { red: 255, green: 255, blue: 255 },
                  'antiAliasStrong',
                  textArea.textLayout == 1 ? 0 : 1,
                  1
                ])
              })
              if (pushPhoneticText.length) {
                pushArr.forEach(a => {
                  if (a[16] == 1) {
                    a[11] = a[11] + pushPhoneticText.length * (phoneticSzie / 2)
                  } else {
                    a[12] = a[12] - pushPhoneticText.length * (phoneticSzie / 2)
                  }
                })
              }
            }
            console.log(pushArr) //pushPhoneticBrIndexArr有问题
            makeArr.push(pushArr)
          })

          postData.list.push(makeArr)
        })
        const res = await v1postAction('/psd/test', {
          ...postData,
          productionId: taskDetail.productionId,
          chapterId: taskDetail.chapterId
        })
        if (res.code == 1) {
          let name = taskDetail.productionName + '-' + taskDetail.chapterOrder + '-制作稿.zip'
          let xhr = new XMLHttpRequest()
          // GET请求,link,async(是否异步)
          xhr.open('GET', '/api/v1' + res.data, true)
          xhr.setRequestHeader('X-Access-Token', Vue.ls.get(ACCESS_TOKEN))
          xhr.responseType = 'blob'
          xhr.onload = function(e) {
            if (this.status == 200) {
              let blob = this.response
              let a = document.createElement('a')
              // blob.type = "application/octet-stream";
              //创键临时link对象
              let url = window.URL.createObjectURL(new Blob([blob]))
              a.href = url
              a.download = name
              a.click()
              // 释放之前创建的URL对象
              window.URL.revokeObjectURL(res.data)
              a.remove()
              that.loading = false
            } else {
              that.loading = false
            }
          }
          // //发送请求
          xhr.send()
        }
      } else {
        that.loading = false
        that.$message.error(res.msg)
      }
    },
    handleCancel() {
      this.visible = false
      this.ownerId = undefined
    },
    async handleOk() {
      this.visible = false
      let saveArr = this.selectKeys.map(item => {
        return {
          taskId: item.taskId,
          ownerId: item.ownerId,
          id: item.taskId,
          chapterId: item.chapterId,
          stageId: item.stageId,
          productionId: this.parent.productionId
        }
      })
      saveArr.forEach(item => {
        item.ownerId = this.ownerId
      })
      const res = await postAction('/tasks/updateTasks', saveArr)
      if (res.code == 200) {
        this.$message.success('保存成功')
        this.ownerId = undefined
        this.load()
        /* this.clearSelectKeys() */
      } else {
        this.$message.error(res.msg)
      }
    },
    handleUserSelectOn() {
      this.visible = true
    },
    selectChange(taskAll, stage, stages, task) {
      if (task.isEdit) return
      task.isSelect = !task.isSelect
      this.$emit('getKey', {
        ...task,
        checked: task.isSelect,
        stageName: stage.stageName,
        stageList: stages,
        stageId: stage.stageId,
        chapterId: taskAll.chapterId
      })
      if (task.isSelect) {
        this.checkedList.push(task)
      } else {
        if (this.checkedList.findIndex(item => item.taskId == task.taskId) > -1) {
          this.checkedList.splice(
            this.checkedList.findIndex(item => item.taskId == task.taskId),
            1
          )
        }
      }
      if (task.isSelect) {
        this.selectUsers = stage.users
      }
      this.$forceUpdate()
    },
    onChange(e, data, stage, stages) {
      console.log(stage, stages)
      this.$emit('getKey', {
        checked: e.target.checked,
        ...data,
        stageName: stage.stageName,
        stageList: stages,
        stageId: stage.stageId
      })
    },
    handleScroll(e) {
      this.oldTop = e.target.scrollTop || this.oldTop
      if (!this.isBottom) {
        this.scrollNum = parseInt(e.target.scrollTop / 140)
      }
      if (e.target.scrollTop + e.target.clientHeight == e.target.scrollHeight) {
        this.bottomLength = 0
        this.scrollNum = this.chapters.length - this.showNum
      }
      this.isBottom = false
    },
    dateChange(e, task) {
      if (this.saveData.length) {
        if (
          this.saveData.some(item => {
            return item.taskId == task.taskId
          })
        ) {
          this.saveData.forEach(item => {
            if (item.taskId == task.taskId) {
              item.deadline = e + ':00'
            }
          })
        } else {
          this.saveData.push({ taskId: task.taskId, ownerId: task.ownerId, deadline: e + ':00' })
        }
      } else {
        this.saveData.push({ taskId: task.taskId, ownerId: task.ownerId, deadline: e + ':00' })
      }
    },
    userSelectChange(e, task) {
      if (this.saveData.length) {
        if (
          this.saveData.some(item => {
            return item.taskId == task.taskId
          })
        ) {
          this.saveData.forEach(item => {
            if (item.taskId == task.taskId) {
              item.ownerId = e
            }
          })
        } else {
          this.saveData.push({ taskId: task.taskId, ownerId: e, deadline: task.deadline })
        }
      } else {
        this.saveData.push({ taskId: task.taskId, ownerId: e, deadline: task.deadline })
      }
    },
    getSelectUser(users, item) {
      let arr = []
      users.forEach(user => {
        for (let key in user.positions) {
          if (
            this.stages.some(stage => {
              return stage.positionId == user.positions[key].positionId && stage.stageName == item.stageName
            })
          ) {
            arr.push(user)
            break
          }
        }
      })
      return arr
    },
    async enterEdit() {
      if (!this.selectKeys.length) {
        this.$message.warning('请选择一条记录！')
        return
      }
      if (this.checkedList.some(item => item.isEdit)) {
        const res = await postAction('/tasks/boardUpdate', this.saveData)
        if (res.code == 200) {
          this.$message.success('保存成功')
          this.checkedList.forEach(item => {
            item.isEdit = false
          })
          this.checkedList = []
          this.$forceUpdate()
        } else {
          this.$message.error(res.msg || res.message)
        }
        this.reloadData()
      } else {
        this.checkedList.forEach(item => {
          if (item.isSelect) {
            item.isEdit = true
          }
        })
        this.$forceUpdate()
        if (!this.isMyTask) {
        }
        /* this.oldKanbanData = JSON.parse(JSON.stringify(this.kanbanData)) */
      }
    },
    goToFatchStraw(task) {
      /*       this.$confirm({
        title: '是否加载已锁定状态的文件?',
        okText: '加载',
        cancelText: '不加载',
        onOk: () => {
          window.open(`/fatchStraw/${task.taskId}/${task.chapterId}/0?loadLock=true`)
        },
        onCancel: () => {
          window.open(`/fatchStraw/${task.taskId}/${task.chapterId}/0`)
        }
      }) */
      this.confirmVisible = true
      this.confirmTask = task
      /*       if (task.departName.indexOf('原创部') > -1) {
        window.open(`/fatchStraw/${task.taskId}/${task.chapterId}/0?loadLock=true`)
      } else {
        this.confirmVisible = true
        this.confirmTask = task
      } */
    },
    handleConfimCancel(task) {
      this.confirmVisible = false
      /* window.open(`/fatchStraw/${task.taskId}/${task.chapterId}/0`) */
      const newUrl = `/fatchStraw/${task.taskId}/${task.chapterId}/0`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    handleConfimOk(task) {
      this.confirmVisible = false
      const newUrl = `/fatchStraw/${task.taskId}/${task.chapterId}/0?loadLock=true`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
      /* window.open(`/fatchStraw/${task.taskId}/${task.chapterId}/0?loadLock=true`) */
    },
    goToTranslate(task) {
      /*       this.$router.push({
        path: `/translate/editor/${taskDetail.taskId}/${taskDetail.productionId}/${taskDetail.chapterId}/${taskDetail.platformId}`
      }) */
      localStorage.setItem('translate_record', JSON.stringify(task))
      /* window.open(
        `/translate/editor/${taskDetail.taskId}/${taskDetail.productionId}/${taskDetail.chapterId}/${taskDetail.platformId}?productionName=` +
          taskDetail.productionName+'&targetLang='+this.targetLang
      ) */
      localStorage.setItem('translate_download', '')
      /* window.open(`/translate/${task.taskId}/${task.chapterId}/0/0`) */
      const newUrl = `/translate/${task.taskId}/${task.chapterId}/0/0`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    checkPermission() {
      return checkPermission('production:task:button:edit')
    },
    batchSelect() {
      this.isSelect = !this.isSelect
      this.eidtDis = !this.eidtDis
      this.$emit('setSelectKeys')
    },
    filterCondition(item) {
      if (!this.filterValue.length) {
        return true
      } else if (this.filterValue.some(i => i == item.taskStatus)) {
        return true
      }
      return false
    },
    gotoDetail(item) {
      console.log(item)
      if (this.checkedList.some(item => item.isEdit) || this.isSelect) return
      /*       this.$router.push({
        path: `/translate/mytasks/detail/${item.taskId}/${this.parent.productionId}/${
          item.chapterId
        }?fromProduction=1&record=${encodeURIComponent(
          JSON.stringify({
            platformId: this.parent.platformId,
            productionName: this.parent.productionName,
            departmentIds: this.parent.departmentIds
          })
        )}&name=${this.parent.productionName + '-' + item.sequenceNo + '-' + item.stageName}`
      }) */
      const newUrl = `/translate/mytasks/detail/${item.taskId}/${this.parent.productionId}/${
        item.chapterId
      }?fromProduction=1&record=${encodeURIComponent(
        JSON.stringify({
          platformId: this.parent.platformId,
          productionName: this.parent.productionName,
          departmentIds: this.parent.departmentIds
        })
      )}&name=${this.parent.productionName + '-' + item.sequenceNo + '-' + item.stageName}`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    gotoSumbmit(record) {
      this.$router.push({
        path: `/translate/mytasks/upload/${record.taskId}/${record.productionId}/${
          record.chapterId
        }?&record=${encodeURIComponent(
          JSON.stringify({ platformId: record.platformId, productionName: record.productionName })
        )}&name=${record.productionName + '-' + record.chapterOrder + '-' + record.stageName}&type=上传完成稿`
      })
    },
    goToUpload(record) {
      this.$router.push({
        path: `/translate/mytasks/upload/${record.taskId}/${record.productionId}/${
          record.chapterId
        }?&record=${encodeURIComponent(
          JSON.stringify({ platformId: record.platformId, productionName: record.productionName })
        )}&name=${record.productionName + '-' + record.chapterOrder + '-' + record.stageName}&type=上传原稿`
      })
    },
    cancel() {
      this.checkedList = []
      this.$emit('setBatchDis', false)
    },
    reloadData() {
      console.log('task-board reload')
      this.chapters = []
      this.current = 1
      this.isSort = false
      this.load()
      this.$emit('setSelectKeys')
    },
    async load() {
      if (this.loading) {
        return
      }
      this.loading = true
      if (document.getElementsByClassName('boardContent')[0]) {
        document.getElementsByClassName('boardContent')[0].scrollTop = 0
      }
      this.scrollNum = 0
      this.loadChapters()
    },
    async getAllUsers() {
      const res = await postAction('/sys/user/queryUser', { departmentIds: this.parent.departmentIds })
      this.allUsers = Object.assign([], res.data)
      this.getUsers()
    },
    sortKanban() {
      if (this.isSort) {
        this.isSort = false
      } else {
        this.isSort = true
      }
      if (this.isSort) {
        this.chapters = this.chapters.sort((a, b) => b.sequenceNo - a.sequenceNo)
      } else {
        this.chapters = this.chapters.sort((a, b) => a.sequenceNo - b.sequenceNo)
      }
      this.copyChapters = this.chapters.filter((item, index) => {
        return index < 5
      })
      if (document.getElementsByClassName('boardContent')[0]) {
        document.getElementsByClassName('boardContent')[0].scrollTop = 0
      }
      this.scrollNum = 0
    },
    async loadChapters() {
      const { data } = await getAction('/production/board', {
        productId: this.parent.productionId,
        current: this.current,
        size: this.size
      })
      /* data.chapters=data.chapters.sort((a,b)=>b.sequenceNo-a.sequenceNo) */
      if (this.isSort) {
        data.chapters = data.chapters.sort((a, b) => b.sequenceNo - a.sequenceNo)
      } else {
        data.chapters = data.chapters.sort((a, b) => a.sequenceNo - b.sequenceNo)
      }
      this.chapters = data.chapters
      this.chapters.forEach(chapter => {
        for (let stageId in chapter.stageIdTask) {
          chapter.stageIdTask[stageId].isSelect = false
          chapter.stageIdTask[stageId].isEdit = false
          // if(chapter.stageIdTask[stageId].rejectTaskId){
          //   // 如果被驳回过，但驳回给当前任务的任务被删除
          //   if(Object.entries(chapter.stageIdTask).map(i => i[1]).some(t => t.taskId == chapter.stageIdTask[stageId].rejectTaskId)){
          //     chapter.stageIdTask[stageId].hasReject = true
          //   }
          // }else{
          //   chapter.stageIdTask[stageId].hasReject =false
          // }
        }
      })
      this.copyChapters = this.chapters.filter((item, index) => {
        return index < 5
      })
      this.bottomLength = (this.chapters.length - this.showNum) * 140
      this.current = data.current
      this.isLastPage = data.pages == data.current || (this.size = -1)
      this.loadStages()
    },
    async loadStages() {
      /* const { data } = await getAction('/production/stages', { productId: this.parent.productionId })
        this.stages = data */
      const { data } = await getAction('/production/stages', { productId: this.parent.productionId })
      this.stages = data
      let arr = []
      this.chapters.find(item => item.stageIdTask)
      this.chapters.forEach(chapter => {
        if (chapter.stageIdTask) {
          let nums = []
          for (let i in chapter.stageIdTask) {
            nums.push(i)
          }
          arr.push(nums.length)
        }
      })
      arr.sort((a, b) => {
        return b - a
      })
      let length = arr[0]
      this.chapters.forEach(chapter => {
        if (chapter.stageIdTask) {
          /*             let nums = []
            for (let i in chapter.stageIdTask) {
              nums.push(i)
            }
            if (nums.length == length) {
              let arr = data?.filter(stage => {
                return chapter.stageIdTask[stage.stageId] ? true : false
              })
              if (!this.stagesCopy.length) {
                this.stagesCopy = arr
              } else {
                arr.forEach(a => {
                  if (this.stagesCopy.every(stage => stage.stageName != a.stageName)) {
                    this.stagesCopy.push(a)
                  }
                })
              }
            } */
          let arr = data?.filter(stage => {
            return chapter.stageIdTask[stage.stageId] ? true : false
          })
          if (!this.stagesCopy.length) {
            this.stagesCopy = arr
          } else {
            arr.forEach(a => {
              if (this.stagesCopy.every(stage => stage.stageName != a.stageName)) {
                this.stagesCopy.push(a)
              }
            })
          }
        }
      })
      this.stagesCopy.sort((a, b) => {
        return a.sequenceNo - b.sequenceNo
      })
      this.getAllUsers()
    },
    getUsers() {
      this.stages.forEach(stage => {
        stage.users = this.getSelectUser(this.allUsers, stage)
      })
      this.loading = false
      this.checkedList = []
      if (document.getElementsByClassName('boardContent')[0]) {
        document.getElementsByClassName('boardContent')[0].scrollTop = this.oldTop
        this.scrollNum = parseInt(this.oldTop / 140)
      }
    },
    getTask(chapter, stage) {
      return (
        (chapter.stageIdTask && {
          ...chapter.stageIdTask[stage?.stageId],
          chapterId: chapter.chapterId,
          stageName: stage.stageName,
          sequenceNo: chapter.sequenceNo
        }) ||
        {}
      )
    },
    getTaskStyle(item, chapter) {
      const hasBeenReject = Object.entries(chapter.stageIdTask).some(
        ([_, { rejectTaskId }]) => rejectTaskId == item.taskId
      )
      let color = 'background-color:#fff'
      if (item) {
        if (item.taskStatus == 'REJECTING') {
          color =
            item.deadline && item.deadline.slice(11, 16) < '18'
              ? 'background-color:rgb(255 225 225);border: 2px solid #ffa39e;'
              : item.priority == 'HIGH'
              ? 'background-color:rgb(255 225 225);border: 2px solid #ffa39e;'
              : item.finishStatus == 'DELAY'
              ? 'background-color:#fff9b7;color:orange;'
              : hasBeenReject
              ? 'background-color:rgb(255 229 199);color:orange;'
              : 'background-color:#fff;color:orange;'
        } else if (item.taskStatus == 'FINISHED') {
          color =
            item.deadline && item.deadline.slice(11, 16) < '18' && item.finishStatus != 'DELAY'
              ? 'background-color:#fff;'
              : item.priority == 'HIGH' && item.finishStatus != 'DELAY'
              ? 'background-color:#fff;'
              : item.finishStatus == 'DELAY'
              ? 'background-color:#fff9b7;'
              : 'background-color:#fff;'
        } else if (item.taskStatus == 'STARTED' && hasBeenReject) {
          color = 'background-color:rgb(255 229 199);'
        }
        if (item.finishStatus == 'DELAY') {
          color = 'background-color:#fff9b7;'
        }
      }
      if (item.rejectTaskId && item.taskStatus != 'REJECTING') {
        color = color + 'border: 2px solid orange;'
      }
      return color
    }
  }
}
</script>

<style lang="less" scoped>
.more-icon:hover {
  color: var(--theme-color) !important;
  cursor: pointer;
}
/deep/ .ant-btn-default {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
/deep/ .ant-dropdown-trigger {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.boardTask-select {
  background-color: VAR(--back-color) !important;
}
.boardTask-select {
  transition: background-color 0.3s ease-in-out;
}
.board-shadow {
  transition: box-shadow 0.3s ease-in-out;
}
.productTitle:hover {
  color: VAR(--theme-color);
  cursor: pointer;
}
.kanban-span {
  margin-top: 5px;
}
.chapter-li {
  min-height: 140px;
}
/deep/ .kanban-date .ant-calendar-picker-input {
  font-size: 12px !important;
  width: 135px !important;
}
/deep/ .kanban-date .ant-calendar-picker-icon {
  display: none !important;
}
/deep/ .kanban-date .ant-calendar-picker-clear {
  display: none !important;
}
.taskBoard {
  font-size: 14px;
  overflow-x: auto;
}

.stageTitles {
  display: flex;
  border-bottom: 1px solid #eee;
}
.stageTitle {
  min-width: 180px;
  padding: 10px 10px;
  font-weight: bold;
  color: #333;
}

.boardContent {
  overflow-y: auto;
  overflow-x: hidden;
  height: 60vh;
}

.boardStage {
  min-width: 180px;
  padding: 0px 10px;
}

.boardTask {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 1px 1px 2px rgba(#000, 0.1), -1px 0 1px rgba(#000, 0.06);
  padding: 10px;
  background: #fff;
}
.boardProduct {
  display: flex;
  align-items: center;
}

.productTitle {
  flex: 1;
  max-width: 114px;
}

.boardChapter {
  display: flex;
  color: #333;
}

.boardUser {
  display: flex;
  color: rgba(0, 0, 0, 0.65);
  align-items: center;
  margin-top: 2px;
  font-weight: 500;
}

.boardAvatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 3px;
}

.boardDeadline {
  display: flex;
  font-size: 0.75rem;
  color: #99a0ab;
  justify-content: space-between;
}
</style>
